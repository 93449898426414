.mhover{
    position: fixed;
    top: 15%;
    right: 0%;
    width: 300px;
    height: auto;
    z-index: 999;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    
    
}
.mhover li{
    width: 300px;
    text-align: left;
    cursor: pointer;
    color: #fff;
    font-weight: 500;
    list-style-type: none;
    height: auto;
    text-transform: uppercase;
    padding-top: 10px;
}
.mhover li strong {
    font-weight: 800;
    color: #100056;

}

.mhover li a{
    color: #fff;
    height: auto;
    
}
.mhover li:hover{
    color: black;
}
.mhover li a:hover{
    color: #000;
    font-weight: 700;
}

.bouttonstyles{
    position: fixed;
    top: 26%;
    right: 0%;
    transform: 'tranlate(-50%)';
    width: auto;
    height: 40px;
    background-color: #077f00;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: 700;
}


@media screen and (max-width:768px) {
    .bouttonstyles{
        font-size: 10px;
    position: fixed;
    top: 3%;
    right: 0;
    transform: 'tranlate(-50%)';
    width: auto;
    height: 40px;
    background-color: #077f00;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
.mhover{
    font-size: 10px;
    top: 20%;
}

    
}
