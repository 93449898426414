nav{
    width: 100%;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    background-color: rgb(1, 130, 52);
    margin-left: auto;
    margin-right: auto;
}

nav ul{
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    justify-content: left;
    align-items: center;
    height: 80px;
    padding: 10px;
    
}
.bpelogo{
    width: 20%;
}
.bpelogo img{
    width: 20%;
    /* margin-top: 3px; */
    cursor: pointer;
}
nav li {
    align-content: center;
    align-items: center;
    width: 20%;
    list-style: none;
    justify-content: center;
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    font-weight: 800;
}




@media screen and (max-width: 768px) {
    .bpelogo{
    width: 40%;
}
.bpelogo img{
    width: 40%;
    /* margin-top: 3px; */
    cursor: pointer;
}
nav li{
    width: auto;
    color: #fff;
    font-weight: 800;
}
    
}


/** Le css pour Production **/

.top_image{
    width: 100%;
}
.top_image img{
    width: 100%;
    /* height: 180px; */
    margin-top: 10px;
}
.titrehome{
    margin-left: auto;margin-right: auto;display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin-top: 5px;
    text-transform: uppercase;
    background-color: rgba(1, 130, 52, 0.9);
    padding: 10px;
    border-radius: 20px;
    color: #fff;
    font-weight: 800;
    font-size: 20px;
}
.textehome{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .titrehome{
        margin-left: auto;margin-right: auto;display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        width: 70%;
        margin-top: 10px;
        text-transform: uppercase;
        background-color: rgba(1, 130, 52, 0.9);
        padding: 10px;
        border-radius: 20px;
        color: #fff;
        font-weight: 800;
        font-size: 12px;
    }
    .textehome{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 10px;
        margin-top: 10px;
        font-weight: 600;
    }
    .leboutonvoirplus{
        text-align: center;
    }
    
}
