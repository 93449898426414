.compteur{
    color: #fff;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    /* border-radius: 200px; */

    
}
.compteur h4{
    font-size: 15px;
    text-transform: capitalize;

}
.right{
    text-align: center;
    padding: 1px;
    margin-top: 10px;
    margin-right: 4px;
}
.right h4{
    text-transform: capitalize;
}

@media screen and (max-width:768px) {
    .compteur{
        
    }
    .compteur h4{
        font-size: 10px;
        text-transform: capitalize;
    
    }
    
}