.genre_video_container{
    

}
.videotel{
    display: none;
}
.videoordi{
    margin-right: 50px;
}
.lignevideo{
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    width: 90%;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    /* margin-right: 20px; */
}

.titregenre{
    align-content: center;
    width: 90%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
    padding: 5px;
}
.annoncetitre{
    background-color: rgb(15, 139, 2);
    color: #fff;
    padding: 10px;
    font-size: 15px;
    width: 20%;
    text-align: center;
    border-radius: 15px;
    font-weight: 800;
    text-transform: uppercase;
}


@media screen and (max-width: 768px) {
    .lignevideo{
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    width: 90%;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    /* margin-right: 20px; */
}
    .titregenre{
    align-content: center;
    width: 90%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
    padding: 0px;
}
    .videotel{
        display: block;
        margin-left: auto;
        margin-right: auto;
        align-content: center;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
        iframe{
        border: none;
        width: auto;
        /* padding-top: 20px; */
        margin-bottom: 20px;
        width: 90%;
        height: 250px;
    }
    .annoncetitre{
    background-color: rgb(15, 139, 2);
    color: #fff;
    padding: 10px;
    font-size: 10px;
    width:40%;
    text-align: center;
    border-radius: 15px;
    font-weight: 800;
    text-transform: uppercase;
}
    .videoordi{
        display: none;

    }

}