$color: #008D36;
$bg: #fff;
$size: 5vh; 
$dur: 1000ms;
$delay: $dur/5;

.loader {
  height: $size * 7.5;
  width: $size * 10;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: $bg;
  &_item{
    background-color: $color;
    height: 98%;
    width: $size;
    animation: loading $dur backwards infinite ease-in-out alternate;
  }
  @for $i from 1 through 5{
    &-item--#{$i}{
      animation-delay: $delay * $i;
    }
  }
}

@keyframes loading{
  from{
    transform: scaleY(.1)
  }
  to{
    transform: scaleY(1)
  }
}