
.toptop{
    margin: 0;
    padding: 0;
}
.ecoletiltle{
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 300px;
    margin-bottom: 10px;
    /* margin-top: 10px; */
    text-transform: uppercase;
    /* background-color: #390101; */
    /* background-image: url(../../assets/Portail/ecobg.jpg); */
    background-size: cover;
    width: 100%;
    box-shadow: 10px 5px 5px rgba(138, 138, 138, 0.612);
}
.pourtelephone{
    display: none;
}
.pourodinateur{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.ecolesligne{
    width: 90%;
    display: flex;
    align-items: center;    
    align-content: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

}
.lesecoles{
    display: block;
    width: 30%;
    min-height: 500px;
    border: #009aa7 solid 1px;
    margin: 15px;
    padding-top: 0px;
    border-radius: 15px;
    box-shadow: 10px 5px 5px rgba(138, 138, 138, 0.612);
    
}

.title_ecole{
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
}
.title_ecole:hover {
    background-color: rgba(0, 0, 0, 0.92);
    color: black;
}
.lesecoles img {
    width: 100%;
    cursor: pointer;
}

.lower_ecole{
    /* background-color: #0099a773; */
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.lower_ecole img {
    height: 100px;
    width: auto;
    transition: transform 0.5s ease-in-out;
}
.lower_ecole img:hover{
    transition:none;
}

.lower_ecole img.spin {
    transform: rotate(360deg);
}

.spin {
    animation: spin 10s infinite linear;
}
.spin:hover {
    animation: none;
    transition: 5s;
}

@keyframes spin {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
}


@media screen and (max-width: 1024px) {
    .ecoletiltle{
        /* background-image: url(../../assets/Portail/ecobgph.jpg); */
        background-size: cover;
         /* background-size: contain; */
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-content: center;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        height: 120px;
        box-shadow: 10px 5px 5px rgba(138, 138, 138, 0.612);
        /* display: flex; */
    }
    .lesecoles{
        display: block;
        width: 40%;
        min-height: 300px;
        border: #009aa7 solid 1px;
        margin: 15px;
        padding-top: 0px;
        border-radius: 15px;
        box-shadow: 10px 5px 5px rgba(138, 138, 138, 0.612);
    }
    .lower_ecole img {
        height: 60px;
        width: auto;
        transition: transform 0.5s ease-in-out;
    }
    .pourodinateur{
        display: none;
    }
    .pourtelephone{
        display: block;
        width: 100%;
    }
    .title_ecole{
        padding-top: 5px;
        padding-bottom: 5px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: #fff;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 12px;
        text-align: center;
        padding-left: 2px;
        padding-right: 2px;
    }
}

@media screen and (max-width: 768px) {
    .pourodinateur{
        display: none;
    }
    .pourtelephone{
        display: block;
        width: 100%;
    }
    .ecoletiltle{
        /* background-image: url(../../assets/Portail/ecobgph.jpg); */
        background-size: cover;
         /* background-size: contain; */
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-content: center;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        height: 80px;
        /* display: flex; */
        box-shadow: 10px 5px 5px rgba(138, 138, 138, 0.612);
    }

    .lesecoles{
        display: block;
        width: 60%;
        min-height: 200px;
        border: #009aa7 solid 1px;
        margin: 15px;
        padding-top: 0px;
        border-radius: 15px;
        box-shadow: 10px 5px 5px rgba(138, 138, 138, 0.612);
    }
    .lower_ecole img {
        height: 40px;
        width: auto;
        transition: transform 0.5s ease-in-out;
    }
    .title_ecole{
        padding-top: 5px;
        padding-bottom: 5px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: #fff;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 10px;
        text-align: center;
        padding-left: 2px;
        padding-right: 2px;
    }
}



/* Nouvelles dispositions écoles  */

.thenewschoolshow{
    width: 100%;
}
.newshowtitle{
    width: 100%;
    background: url(https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backgroundecoles.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    align-items: center;align-content: center;justify-content: center;
    display: flex;
}
.newshowtitle h1{
    color: #fff;
    font-size: 45px;
    font-weight: bolder;
    text-transform: uppercase;
}
.newshowtitle span{
    color: #f77f00;
    font-size: 150px;
    font-weight: bolder;
    text-transform: uppercase;
}

.schoolsandcourses{
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    margin-top: 50px;
}

.newschoolsshow{
    width: 75%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}
.schoolshow{
    width: 20% ;
    min-height: auto ;
    margin: 10px;
    cursor: pointer;
    transition: 0.5s;
    /* scale: 0.99; */
}
.schoolshow:hover{
    scale: 1.04;
    transition: 0.5s;
}
.schoolshowtitle{
    width: 100%;
    background-color: #ffffffa1;
    height: 100px;
    text-align: center;
    font-weight: bolder;
    font-size: 15px;
    padding: 3px;
    border-top-left-radius: 10px ;
    border-top-right-radius: 10px ;
    text-transform: uppercase;
    border-bottom: solid #fff 1px;
    align-items: center;align-content: center;justify-content: center;display: flex;
}
.schoolshowimg img{
    width: 100%;
    border-bottom-left-radius: 10px ;
    border-bottom-right-radius: 10px ;
    border-bottom: black solid 2px;

}
.color1{
    background-color: #009aa7;
    color: #fff;
}
.color2{
    background-color: #00a651;
    color: #fff;
}
.color3{
    background-color: #008bd2;
    color: #fff;
}
.color4{
    background-color: #8f0101;
    color: #fff;
}
.color5{
    background-color: #aa2053;
    color: #fff;
}
.color6{ background-color: #cd972d; color: #fff;}
.color7{ background-color: #612c85; color: #fff;}
.color8{ background-color: #ffc000; color: #fff;}
.color9{ background-color: #f7a600; color: #fff;}
.color10{ background-color: #0485af; color: #fff;}
.color11{ background-color: #01650d; color: #fff;}

.newscourseshow{
    width: 20%;
    background-color: #011f4452;
    height: fit-content;
    padding-bottom: 20px;
    padding-top: 20px;
    border-radius: 10px;
    
}
.newscourseshowtitle{
    padding: 20px;
    width: 100%;
    text-align: center;
    font-weight: bolder;
    font-size: 25px;
    color: #fefffe;
    text-transform: uppercase;
}
.newscourseshowfiilieres{
    width: 100%;
    align-items: center;align-content: center;justify-content: center;
}
.newscourseshowfiiliere{
    cursor: pointer;
    margin-left: auto;
    /* border-radius: 10px; */
    margin-right: auto;
    width: 90%;
    min-height: 50px;
    max-height: 80px;
    padding: 10px;
    background-color: #011f44;
    color: #fff;
    margin-bottom: 5px;
    align-items: center;align-content: center;justify-content: center;display: flex;
    flex-direction: column;
}
.newscourseshowfiiliere:hover{
    background-color: #008d36;
    color: #fff;
}
.newscourseshowfiilierename{
    font-weight: bolder;
    font-size: 12px;
    text-transform: uppercase;
}
.newscourseshowfiiliereschool{
    font-weight: bolder;
    font-size: 11px;
    text-transform: uppercase;
}

.newscourseshowsearch{
    width: 80%;
    margin-left: auto;margin-right: auto;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .newshowtitle{
        width: 100%;
        background: url(https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backgroundecoles.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 100px;
    }
    .newshowtitle h1{
        color: #fff;
        font-size: 12px;
        font-weight: bolder;
        text-transform: uppercase;
    }
    .newshowtitle span{
        color: #008d36;
        font-size: 40px;
        font-weight: bolder;
        text-transform: uppercase;
    }
    .schoolsandcourses{
        width: 100%;
        display: block;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    
    .newschoolsshow{
    
        width: 100%;
        align-items: center;align-content: center;justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }
    .schoolshow{
        width: 40% ;
        height: auto ;
        /* background-color: #390101; */
        margin: 5px;
        border-radius: 10px;
    }
    .schoolshowtitle{
        width: 100%;
        background-color: #040000b5;
        height: 70px;
        text-align: center;
        font-weight: bolder;
        font-size: 10px;
        padding: 2px;
        border-top-left-radius: 10px ;
        border-top-right-radius: 10px ;
        text-transform: uppercase;
        border-bottom: solid #fff 1px;
        align-items: center;align-content: center;justify-content: center;display: flex;
    }
    .newscourseshow{
        display: none;
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .newshowtitle{
        width: 100%;
        background: url(https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backgroundecoles.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 250px;
    }
    .newshowtitle h1{
        color: #fff;
        font-size: 35px;
        font-weight: bolder;
        text-transform: uppercase;
    }
    .newshowtitle span{
        color: #f77f00;
        font-size: 120px;
        font-weight: bolder;
        text-transform: uppercase;
    }
    .schoolsandcourses{
        width: 100%;
        display: block;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    
    .newschoolsshow{
    
        width: 100%;
        align-items: center;align-content: center;justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }
    .schoolshow{
        width: 30% ;
        min-height: 300px ;
        background-color: #390101;
        margin: 5px;
        border-radius: 10px;
    }
    .schoolshowtitle{
        width: 100%;
        /* background-color: #040000b5; */
        height: 90px;
        text-align: center;
        font-weight: bolder;
        font-size: 12px;
        padding: 2px;
        border-top-left-radius: 10px ;
        border-top-right-radius: 10px ;
        text-transform: uppercase;
        border-bottom: solid #fff 1px;
        align-items: center;align-content: center;justify-content: center;display: flex;
    }
    .newscourseshow{
        width: 90%;
        margin-left: auto;margin-right: auto;
        margin-top: 20px;
    }
    .newscourseshowtitle{
        padding: 20px;
        width: 100%;
        text-align: center;
        font-weight: bolder;
        font-size: 25px;
    }
    .newscourseshowfiilieres{
        width: 100%;
        align-items: center;align-content: center;justify-content: center;
    }
    .newscourseshowfiiliere{
        cursor: pointer;
        margin-left: auto;
        /* border-radius: 10px; */
        margin-right: auto;
        width: 90%;
        min-height: 50px;
        max-height: 80px;
        padding: 10px;
        /* background-color: #0909096b; */
        margin-bottom: 5px;
        align-items: center;align-content: center;justify-content: center;display: flex;
        flex-direction: column;
    }
    .newscourseshowfiiliere:hover{
        /* background-color: #008d36; */
        color: #fff;
    }
    .newscourseshowfiilierename{
        font-weight: bolder;
        font-size: 15px;
        text-transform: uppercase;
    }
    .newscourseshowfiiliereschool{
        font-weight: bolder;
        font-size: 18px;
        text-transform: uppercase;
        /* color: #390101; */
    }
}