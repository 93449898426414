@keyframes party {
    0% { background-color: red; }
    25% { background-color: orange; }
    50% { background-color: yellow; }
    75% { background-color: green; }
    100% { background-color: blue; }
  }
  @keyframes sparkle {
    0% { background: red; box-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red; }
    100% { background: blue; box-shadow: 0 0 5px blue, 0 0 10px blue, 0 0 15px blue, 0 0 20px blue; }
  }
  @keyframes particleEffect {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100vh);
      opacity: 0;
    }
  }


.popup_image{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8%;
    left: 21%;
    /* right: auto; */
    background-color: rgba(163, 181, 205, 0.8);
    padding: 10px;
    border-radius: 15px;
    border: #fff solid 1px;
}
/* .popup_image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/Placeholder/particles.png') no-repeat;
    animation: particleEffect 2s linear infinite;
  } */

.popup_image img{
    width: 100%;
    border-radius: 15px;
    border: #fff solid 2px;
}

.closemodal{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 800;
    background-color: rgb(1, 78, 14);
    color: #fff;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-bottom: 10px;
    
}
.lienmodal{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    background-color: rgb(255, 255, 255);
    border: none;
    color: rgb(1, 78, 14);
    padding: 5px;
    width: 20%;
    text-transform: uppercase;
    border-radius: 10px;
    font-weight: 800;
    border: rgb(1, 78, 14) solid 1px;
}
@media screen and (max-width: 768px) {
    .popup_image{
        width: 90%;
        position: absolute;
        top: 25%;
        left: 5%;
        z-index:9999;
    }
    .closemodal{
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        align-content: center;
        align-items: center;
        justify-content: center;
        display: flex;
        font-weight: 800;
        background-color: rgb(1, 78, 14);
        color: #fff;
        border: none;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin-bottom: 10px;
        
    }
    .lienmodal{
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        margin-top: 10px;
        background-color: rgb(255, 255, 255);
        border: none;
        color: rgb(1, 78, 14);
        padding: 5px;
        width: 50%;
        text-transform: uppercase;
        border-radius: 10px;
        font-weight: 800;
        border: rgb(1, 78, 14) solid 1px;
    }
    
}