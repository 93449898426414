.container{
    width: 100%;
    position: relative;
    height: 100%;
}
.iconcontainer{
    width: 100%;
    position: relative;
    background-color: #0c07004e;
}
.befortop{
    background-color: rgba(204, 203, 203, 0.927);
    width: 100%;
    position: fixed;
    top: 0;
    height: 100px;
    z-index: 9999;
}

.topmenuphone{
    display: none;
}
.topbar {
    width: 100%;
    height: 100px;
    /* background-color: rgb(0, 141, 54); */
    display: flex;
   
    z-index: 999;
   
}
.topbarShrinkbefor {
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
    border-bottom: rgb(255, 255, 255) solid 3px;
    background-color: rgba(0, 141, 54, 0.708);
}
.topbarShrink {
    transform: scale(0.9);
    transition: transform 0.3s ease-in-out;
}

.topmenucontentShrink {
    font-size: 0.9em; /* Exemple de réduction de la taille du texte */
    /* border-bottom: #ffcc00 solid 2px; */
}


/* LE CONTENU DU TOPBAR */

.topmenucontent{
    align-items: center;align-content: center;justify-content: center;display: flex;
    width: 90%;
    margin-left: auto;margin-right: auto;
}
.topmenucontent1{
    transition: 0.3s;
    margin-right: 30px;
    cursor: pointer;
    width: 10%;
    /* background-color: #f7f7f7ee; */
    padding: 1px;
    /* border: #ffcc00 solid 2px; */
    border-radius: 10px;
    align-items: center;align-content: center;justify-content: center;display: flex;
}

.topmenucontent1 img{
    width: 50%;
}
.topmenucontent2{
    align-items: center;align-content: center;justify-content: center;display: flex;
    /* width: 60%; */
}
.topmenucontent2item1{
    width: 100px;
    background-color: #00bbcc;
    height: 100px;
    border-left: 2px solid #00bbcc;
    border-right: 2px solid rgb(255, 204, 0);
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 800;
    color: #f7f7f7;
    cursor: pointer;
    transition: 0.3s;
}
.topmenucontent2item1:hover{
    height: 120px;
    margin-top: 10px;
    transition: 0.3s;
    background-color: #00bbcc;
    color: #ffcc00;
    border-bottom-left-radius: 10px;

}
.topmenucontent2item2{
    width: 100px;
    background-color: rgb(255, 204, 0);
    height: 100px;
    border-left: 2px solid #ffffff;
    border-right: 2px solid rgba(255, 204, 0, 1);
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 800;
    color: #000000;
    cursor: pointer;
    transition: 0.3s;
}
.topmenucontent2item2:hover{
    height: 120px;
    margin-top: 10px;
    transition: 0.3s;
    background-color: rgba(255, 204, 0, 1);
    color: #ffffff;
    border-bottom-left-radius: 10px;

}
.topmenucontent2item3{
    width: 100px;
    background-color: rgb(255, 255, 255);
    height: 100px;
    border-left: 2px solid #ffffff;
    border-right: 2px solid rgba(96, 96, 95, 1);
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 800;
    color: #000000;
    cursor: pointer;
    transition: 0.3s;
}
.topmenucontent2item3:hover{
    height: 120px;
    margin-top: 10px;
    transition: 0.3s;
    background-color: rgba(96, 96, 95, 1);
    color: #ffffff;
    border-bottom-left-radius: 10px;

}
.topmenucontent2item4{
    width: 100px;
    background-color: rgb(112, 1, 104);
    height: 100px;
    border-left: 2px solid #ffffff;
    border-right: 2px solid rgba(112, 1, 105, 1);
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 800;
    color: #ffffff;
    cursor: pointer;
    transition: 0.3s;
}
.topmenucontent2item4:hover{
    height: 120px;
    margin-top: 10px;
    transition: 0.3s;
    background-color: rgba(112, 1, 105, 1);
    color: rgba(255, 204, 0, 1);
    border-bottom-left-radius: 10px;

}
.topmenucontent2item5{
    width: 100px;
    background-color: rgb(1, 73, 145);
    height: 100px;
    border-left: 2px solid #ffffff;
    border-right: 2px solid rgb(1, 73, 145);
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 800;
    color: #ffffff;
    cursor: pointer;
    transition: 0.3s;
}
.topmenucontent2item5:hover{
    height: 120px;
    margin-top: 10px;
    transition: 0.3s;
    background-color: rgb(1, 73, 145);
    color: rgba(255, 204, 0, 1);
    border-bottom-left-radius: 10px;

}
.topmenucontent2item6{
    width: 100px;
    background-color: rgb(1, 112, 31);
    height: 100px;
    border-left: 2px solid #ffffff;
    border-right: 2px solid rgba(1, 112, 31, 1);
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 800;
    color: #ffffff;
    cursor: pointer;
    transition: 0.3s;
}
.topmenucontent2item6:hover{
    height: 120px;
    margin-top: 10px;
    transition: 0.3s;
    background-color: rgba(1, 112, 31, 1);
    color: rgba(255, 204, 0, 1);
    border-bottom-left-radius: 10px;

}
.topmenucontent2item7{
    width: 100px;
    background-color: rgb(255, 140, 0);
    height: 100px;
    border-left: 2px solid #ffffff;
    border-right: 2px solid rgb(255, 140, 0);
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 800;
    color: #ffffff;
    cursor: pointer;
    transition: 0.3s;
}
.topmenucontent2item7:hover{
    height: 120px;
    margin-top: 10px;
    transition: 0.3s;
    background-color: rgb(255, 140, 0);
    color: rgb(41, 3, 79);
    border-bottom-left-radius: 10px;

}
.topmenucontent2item8{
    width: 100px;
    background-color: rgb(4, 127, 193);
    height: 100px;
    border-left: 2px solid #ffffff;
    border-right: 2px solid rgb(4, 127, 193);
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 800;
    color: #ffffff;
    cursor: pointer;
    transition: 0.3s;
}
.topmenucontent2item8:hover{
    height: 120px;
    margin-top: 10px;
    transition: 0.3s;
    background-color: rgb(9, 5, 0);
    color: rgb(255, 255, 255);
    border-bottom-left-radius: 10px;

}
.topmenucontent2item9{
    width: 100px;
    background-color: rgb(126, 1, 32);
    height: 100px;
    border-left: 2px solid #ffffff;
    border-right: 2px solid rgb(4, 127, 193);
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 800;
    color: #ffffff;
    cursor: pointer;
    transition: 0.3s;
}
.topmenucontent2item9:hover{
    height: 120px;
    margin-top: 10px;
    transition: 0.3s;
    background-color: rgb(86, 19, 36);
    color: rgb(255, 255, 255);
    border-bottom-left-radius: 10px;

}


/*FINI LE CONTENU TOP BAR ICI*/

.secondtopbar {
    position: relative; /* Ensures the pseudo-element positions relative to this container */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin-top: 100px; /* Push down to make space for the fixed topbar */
    background-color: #01285cc3; /* Fallback background color */
    margin-bottom: 3px;
}

.secondtopbar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('../../assets/Placeholder/PlaceholderBg.jpg') no-repeat center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment:scroll;
    opacity: 0.5; /* Adjust this value to set the desired opacity */
    z-index: -1; /* Positions the pseudo-element behind the content */
}
.secondtopbarcontent{
    width: 80%;
    margin-left: auto;margin-right: auto;
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    /* background-color: #00000045; */
    /* padding: 20px; */
    /* padding: 20px; */
}
.secondtopbarcontent1{
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    /* width: 30%; */
    display: flex;
    /* flex-direction: column; */
    align-items: center;align-content: center;justify-content: center;
    font-size: 50px; margin-right: 50px;
    cursor: pointer;
    transition: 0.4;
    color: #fdfdfd;

}
.secondtopbarcontent1:hover{
    background-color: #ffffffc7;
    padding: 15px;
    color:#000000;
    border-radius: 10px;
    transition: 0.4;
}
.counttext{
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 800;
    margin-left: 10px;
}


.slider{
    /* height: 700px; */
    /* background-color: #022268; */
    border-bottom: #ffcc00 solid 3px ;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
    align-items: center;justify-content: center;align-content: center;
}
.slider img{
    width: 100%;
    height:auto;
}

.iconspresets{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 300px;
    display: flex;
    /* Gradient background color */
    /* background: linear-gradient(to right, rgb(0, 141, 54), rgb(220, 220, 220)); */
    /* background-color: rgb(0, 141, 54); */
    /* border-bottom: #ffcc00 solid 3px;
    border-top: #ffcc00 solid 3px; */

    margin-top: 30px;
    /* border-bottom: #022268 solid 3px; */
    /* position: absolute; */
    /* top: 200px; */
    bottom: -25%;
    right: 0%;
    left: 0%;
    border-radius: 15px;
    /* box-shadow: 2px 6px 5px rgba(0, 0, 0, 0.8); */
}
.iconspresets img{
    transition: 0.3s;
    width: 80%;
    border-radius: 20px;
    border: #ffcc00 solid 3px;

}
.iconspresets img:hover{
    width: 90%;
    transition: 0.3s;
    border-bottom: #fdfdfd solid 3px;
    border-radius: 20px;
}

.portail{
    width: 20%;
    align-items: center;align-content: center;justify-content: center;text-align: center;
    /* border: #f7f7f7 solid 1px; */
    /* border-radius: 10px; */
    height: 85%;
    margin-right: 0px;
    cursor: pointer;
    /* margin-top: 40px; */
    /* background-color: #022268; */
}
.portailtext{
    position:absolute;
    font-size: 12px;
    font-weight: 800;
    bottom: 40%;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 5px;
    width: 150px;
    transition: 0.3s;
    text-transform: uppercase;
}
.portailtext:hover{
    background-color: #022268;
    color:#f7f7f7;
    border-bottom: #f7f7f7 solid 2px;
    transition: 0.3s;
}

.ecoles{
    width: 20%;
    height: 85%;
    cursor: pointer;
    /* border: #f7f7f7 solid 1px; */
    /* border-radius: 10px; */
    margin-right: 0px;
    align-items: center;align-content: center;justify-content: center;text-align: center;
    /* background-color: #022268; */
}
.concours{
    width: 20%;
    height: 85%;
    cursor: pointer;
    /* border: #f7f7f7 solid 1px; */
    /* border-radius: 10px; */
    margin-right: 0px;
    cursor: pointer;
    align-items: center;align-content: center;justify-content: center;text-align: center;

}
.innovation{
    width: 20%;
    height: 85%;
    cursor: pointer;
    /* border: #f7f7f7 solid 1px; */
    /* border-radius: 10px; */
    align-items: center;align-content: center;justify-content: center;text-align: center;

}
.afterslide{
    /* height: 300px;
    background: url('../../assets/Placeholder/PlaceholderBg.jpg'); */
    /* height: 250px; */
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 9999;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    background: url('../../assets/Placeholder/bgapp.jpg');
    background-position: top;
    background-size: cover;
    /* opacity: 0.8; */
    align-items: center;align-content: center;justify-content: center;text-align: center;
    height: 50vh;
}


.application{
    width: 100%;
    margin-left: auto;margin-right: auto;
    align-items: center;align-content: center;justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #01285c8c;
    color: #f7f7f7;
    height: 100%;
    text-transform: uppercase;
    font-weight: 800;
}
.application span{
    font-size: 25px;
}
.application img{
    width: 15%;
    transition: 0.5s;
    cursor: pointer;
}
.application img:hover{
    width: 18%;
    border-radius: 10px;
    /* border: #fdfdfd solid 1px; */
    padding: 3px;
    transition: 0.5s;
}

.lesvideos{
    width: 100%;
    margin-left: auto;margin-right: auto;
    align-items: center;align-content: center;justify-content: center;
    display: none;

}
.unevideo{
    width: auto;
    /* height: 720px; */
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .application span{
        font-size: 15px;
    }
    .application img{
        width: 30%;
        transition: 0.5s;
        cursor: pointer;
    }
    .application img:hover{
        width: 18%;
        border-radius: 10px;
        /* border: #fdfdfd solid 1px; */
        padding: 3px;
        transition: 0.5s;
    }
    .topbar{
        width: 100%;
        height: 50px;
        /* background-color: rgb(72, 1, 1); */
    }
    .secondtopbar{
        height: 90px;
        /* background-color: #f7f7f7; */
        margin-top: 100px;
        margin-bottom: 2px;
    }
    .secondtopbarcontent{
        width: 90%;
        margin-left: auto;margin-right: auto;
        align-items: center;align-content: center;justify-content: center;
        display: flex;
        /* padding: 20px; */
    }
    .secondtopbarcontent1{
        /* width: 30%; */
        display: flex;
        /* flex-direction: column; */
        align-items: center;align-content: center;justify-content: center;
        font-size: 18px; margin-right: 10px;
        cursor: pointer;
        transition: 0.4;
        flex-wrap: nowrap;
        color: #ffffff;
    
    }
    .secondtopbarcontent1:hover{
        background-color: #00bbccaf;
        padding: 5px;
        color: #f7f7f7;
        border-radius: 5px;
        transition: 0.4;
    }
    .counttext{
        font-size: 8px;
        text-transform: uppercase;
        font-weight: 800;
        margin-left: 5px;

    }
    .slider{
        /* height: 300px; */
        /* background-color: #022268; */
        width: 100%;
    }

    .slider img{
        width: 100%;
        height: 100%;
    }
    .iconspresets{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        /* background-color: red; */
        /* position:static; */
        /* top: 200px; */
        bottom: -100%;
        right: 0%;
        left: 0%;
        border-radius: 10px;
        border-top: #f7f7f7 solid 1px;
        margin-top: 30px;

    }
    .iconspresets img{
        transition: 0.3s;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    .iconspresets img:hover{
        width: 75%;
        transition: 0.3s;
        border-bottom: #022268 solid 3px;
        border-radius: 20px;
    }
    
    .portail{
        width: 50%;
        /* margin-right: auto;margin-left: auto; */
        /* display: flex; */
        align-items: center;align-content: center;justify-content: center;text-align: center;
        /* border: #f7f7f7 solid 1px; */
        /* border-radius: 10px; */
        height: auto;
        margin-right: 0px;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .portailtext{
        /* display: none; */
        margin-left: auto;margin-right: auto;
        margin-top: 5px;
        position:relative;
        font-size: 8px;
        font-weight: 800;
        bottom: 0%;
        background-color: #f7f7f7;
        border-radius: 10px;
        padding: 5px;
        width: 120px;
        transition: 0.3s;
    }
    .portailtext:hover{
        background-color: #022268;
        color:#f7f7f7;
        border-bottom: #f7f7f7 solid 2px;
        transition: 0.3s;
    }
    
    .ecoles{
        width: 50%;
        height: 85%;
        cursor: pointer;
        /* border: #f7f7f7 solid 1px; */
        /* border-radius: 10px; */
        margin-right: 0px;
        margin-bottom: 5px;
        align-items: center;align-content: center;justify-content: center;text-align: center;
    
    }
    .concours{
        width: 50%;
        height: 85%;
        cursor: pointer;
        /* border: #f7f7f7 solid 1px; */
        /* border-radius: 10px; */
        margin-right: 0px;
        cursor: pointer;
        align-items: center;align-content: center;justify-content: center;text-align: center;
        margin-bottom: 5px;
        
    }
    .innovation{
        width: 50%;
        height: 85%;
        cursor: pointer;
        /* border: #f7f7f7 solid 1px; */
        /* border-radius: 10px; */
        align-items: center;align-content: center;justify-content: center;text-align: center;
    }
    .afterslide{
        height: 30vh;
        margin-top: 20px;
        margin-bottom: 10px;
        /* height: 70px; */
    /* background: url('../../assets/Placeholder/phoneBG.jpg'); */
    /* margin-bottom: 150px; */
    }
    .topmenucontent2{
        display: none;
    }

    .befortop{
        background-color: rgba(204, 203, 203, 0.927);
        width: 100%;
        position: fixed;
        top: 0;
        height: 100px;
        z-index: 9999;
        align-items: center;align-content: center;justify-content: center;display: flex;
    }
    .topbarShrinkbefor {
        transform: scale(1);
        transition: transform 0.3s ease-in-out;
        border-bottom: rgb(255, 255, 255) solid 2px;
        background-color: rgba(0, 141, 54, 0.708);
    }
    .topmenucontent1{
        transition: 0.3s;
        margin-right: 30px;
        cursor: pointer;
        width: 50%;
        /* background-color: #f7f7f7ee; */
        padding: 1px;
        /* border: #ffcc00 solid 2px; */
        border-radius: 10px;
        align-items: center;align-content: center;justify-content: center;display: flex;
    }
    
    .topmenucontent1 img{
        width: 40%;
    }
    .topmenuphone{
        display: block;
        align-items: center;align-content: center;justify-content: center;text-align: center;
        transform: translateX(100%); /* Initially move it off-screen to the right */
        transition: transform 0.8s ease-out; /* Smooth transition for sliding effect */
        z-index: 999999;
    }
    .iconwhite{
        color: #f7f7f7;
        /* font-weight: normal; */
        font-size: 25px;
        z-index: 9999999;
    }
    .menutelephonelibre{
        position: absolute;
        background-color: rgb(129, 127, 127);
        z-index: 9999;
        top: 100%;
        right: 0;
        height: 70vh;
        padding-top: 50px;
        width: 90%;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        box-shadow: 2px 6px 5px rgba(0, 0, 0, 0.8);    
    }
    .menutelephonelibreitem1{
        z-index: 99999;
        width: 100%;
        background-color: #00bbcc;
        position: relative;
        height: 50px;
        /* left: 40px; */
        /* border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; */
        /* display: flex; */
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;align-content: center;justify-content: center;
        /* justify-content: center; */
        color: #fdfdfd;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 1px;

        /* right: -40px; */
    }
    .menutelephonelibreitem2{
        width: 100%;
        background-color: rgb(255, 204, 0);
        position: relative;
        height: 50px;
        /* left: -40px; */
        /* border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; */
        /* display: flex; */
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;align-content: center;justify-content: center;
        /* justify-content: center; */
        color: #fdfdfd;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 1px;
        /* right: -40px; */
    }
    .menutelephonelibreitem3{
        z-index: 99999;
        width: 100%;
        background-color: rgb(255, 255, 255);
        position: relative;
        height: 50px;
        /* left: -40px; */
        /* border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; */
        /* display: flex; */
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;align-content: center;justify-content: center;
        /* justify-content: center; */
        color: #050505;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 1px;
        /* right: -40px; */
    }
    .menutelephonelibreitem4{
        width: 100%;
        background-color: rgb(112, 1, 104);
        position: relative;
        height: 50px;
        /* left: -40px; */
        /* border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; */
        /* display: flex; */
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;align-content: center;justify-content: center;
        /* justify-content: center; */
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 1px;
        /* right: -40px; */
    }
    .menutelephonelibreitem5{
        width: 100%;
        background-color: rgb(1, 73, 145);
        position: relative;
        height: 50px;
        /* left: -40px; */
        /* border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; */
        /* display: flex; */
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;align-content: center;justify-content: center;
        text-align: left;
        /* justify-content: center; */
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 1px;
        /* right: -40px; */
    }
    .menutelephonelibreitem6{
        width: 100%;
        background-color: rgb(1, 112, 31);
        position: relative;
        height: 50px;
        /* left: -40px; */
        /* border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; */
        /* display: flex; */
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;align-content: center;justify-content: center;
        /* justify-content: center; */
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 1px;
        text-align: left;
        /* right: -40px; */
    }
    .menutelephonelibreitem7{
        width: 100%;
        background-color: rgb(255, 140, 0);
        position: relative;
        height: 50px;
        /* left: -40px; */
        /* border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; */
        /* display: flex; */
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;align-content: center;justify-content: center;
        /* justify-content: center; */
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 1px;
        text-align: left;
        /* right: -40px; */
    }
    .menutelephonelibreitem8{
        width: 100%;
        background-color: rgb(4, 127, 193);
        position: relative;
        height: 50px;
        /* left: -40px; */
        /* border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; */
        /* display: flex; */
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;align-content: center;justify-content: center;
        /* justify-content: center; */
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 1px;
        text-align: left;
        /* right: -40px; */
    }
    .menutelephonelibreitem8a{
        width: 100%;
        background-color: rgb(141, 2, 46);
        position: relative;
        height: 50px;
        /* left: -40px; */
        /* border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; */
        /* display: flex; */
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;align-content: center;justify-content: center;
        /* justify-content: center; */
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 1px;
        text-align: left;
        /* right: -40px; */
    }


    
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .topmenuphone{
        display: none;
    }

    .topbar{
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        transform: scale(0.8);

        /* height: 50px; */
        /* background-color: rgb(255, 128, 0); */
    }

    .topbarShrinkbefor {
        transform: scale(1);
        transition: transform 0.3s ease-in-out;
        border-bottom: rgb(255, 255, 255) solid 3px;
        background-color: rgba(0, 141, 54, 0.708);
    }
    .topbarShrink {
        transform: scale(0.6);
        transition: transform 0.3s ease-in-out;
    }
    
    .topmenucontentShrink {
        font-size: 0.9em; /* Exemple de réduction de la taille du texte */
        /* border-bottom: #ffcc00 solid 2px; */
    }
    .secondtopbar{
        width: 100%;
        height: 90px;
        align-items: center;align-content: center;justify-content: center;
        margin-left: auto;margin-right: auto;display: flex;
        /* background-color: #f7f7f7; */
    }
    .secondtopbarcontent1{
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        /* width: 30%; */
        display: flex;
        /* flex-direction: column; */
        align-items: center;align-content: center;justify-content: center;
        font-size: 40px; margin-right: 50px;
        cursor: pointer;
        transition: 0.4;
        color: #fdfdfd;
    
    }
    .secondtopbarcontent1:hover{
        background-color: #ffffffc7;
        padding: 10px;
        color:#000000;
        border-radius: 10px;
        transition: 0.4;
    }
    .slider{
        /* height: 400px; */
        background-color: #022268;
    }
    .iconspresets{
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        /* background-color: red; */
        /* position: absolute; */
        /* top: 200px; */
        bottom: 10%;
        right: 5%;
        left: 5%;
        border-radius: 10px;
        border-top: #f7f7f7 solid 2px;

    }

    .iconspresets img{
        transition: 0.3s;
        width: 70%;
    }
    .iconspresets img:hover{
        width: 75%;
        transition: 0.3s;
        border-bottom: #022268 solid 3px;
        border-radius: 20px;
    }
    
    .portail{
        width: 50%;
        align-items: center;align-content: center;justify-content: center;text-align: center;
        /* border: #f7f7f7 solid 1px; */
        /* border-radius: 10px; */
        height: 85%;
        margin-right: 0px;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .portailtext{
        position:relative;
        font-size: 10px;
        font-weight: 800;
        bottom: 40%;
        background-color: #f7f7f7;
        border-radius: 10px;
        padding: 5px;
        width: 150px;
        transition: 0.3s;
        margin-top: 10px;
        margin-left: auto;margin-right: auto;

    }
    .portailtext:hover{
        background-color: #022268;
        color:#f7f7f7;
        border-bottom: #f7f7f7 solid 2px;
        transition: 0.3s;
    }
    
    .ecoles{
        width: 50%;
        height: 85%;
        cursor: pointer;
        /* border: #f7f7f7 solid 1px; */
        /* border-radius: 10px; */
        margin-right: 0px;
        align-items: center;align-content: center;justify-content: center;text-align: center;
        margin-bottom: 20px;
    
    }
    .concours{
        width: 50%;
        height: 85%;
        cursor: pointer;
        /* border: #f7f7f7 solid 1px; */
        /* border-radius: 10px; */
        margin-right: 0px;
        cursor: pointer;
        align-items: center;align-content: center;justify-content: center;text-align: center;
        margin-bottom: 20px;
    }
    .innovation{
        width: 50%;
        height: 85%;
        cursor: pointer;
        /* border: #f7f7f7 solid 1px; */
        /* border-radius: 10px; */
        align-items: center;align-content: center;justify-content: center;text-align: center;
        margin-bottom: 20px;
        
    }


    .afterslide{
        /* height: 300px;
        background: url('../../assets/Placeholder/PlaceholderBg.jpg'); */
        /* height: 250px; */
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 9999;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        margin-bottom: 10px;
        background: url('../../assets/Placeholder/bgapp.jpg');
        background-position: top;
        background-size: cover;
        /* opacity: 0.8; */
        align-items: center;align-content: center;justify-content: center;text-align: center;
        height: 25vh;
    }
    
    
    .application{
        width: 100%;
        margin-left: auto;margin-right: auto;
        align-items: center;align-content: center;justify-content: center;
        display: flex;
        flex-direction: column;
        background-color: #01285c8c;
        color: #f7f7f7;
        height: 100%;
        text-transform: uppercase;
        font-weight: 800;
    }
    .application span{
        font-size: 15px;
    }
    .application img{
        width: 20%;
        transition: 0.5s;
        cursor: pointer;
    }
    .application img:hover{
        width: 18%;
        border-radius: 10px;
        /* border: #fdfdfd solid 1px; */
        padding: 3px;
        transition: 0.5s;
    }
}



/* Pour les concours  */

.titreformation {
    width: 100%;
    background-color: #024b01;
    height: 100px;
    align-items: center; align-content: center;justify-content: center;
    display: flex;
    color: #fdfdfd;
}
.titreformation h1{
    text-transform: uppercase;
    font-size: 25px;

}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 320px) and (max-width: 767px) {}



/* Pour les cours et filières  */
.destination{
    width: 100%;
    align-items: center;align-content: center;justify-content: center;display: flex;
    margin-top: 50px;
    font-weight: bolder;
    font-size: 35px;
    /* background-color: #00000080; */
}

.searchbar{
    width: 100%;
    align-items: center;align-content: center;justify-content: center;display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #f7f7f763;
    padding: 20px;
    border-radius: 20px;
}
.searchInput {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
.labarre{
    width: 80%;
    align-items: center;align-content: center;justify-content: center;display: flex;
}
.labarre input{
    padding: 10px;
    width: 60%;
    border-radius: 15px;
    margin-bottom: 10px;
}
.checkboxContainer{
    width: 100%;
    align-items: center;align-content: center;justify-content: center;display: flex;
}
.checkboxContainer label{
    margin-right: 15px;
    font-weight: bolder;
    font-size: 20px;
}
.checkboxContainer label input{
    margin-right: 2px;
}

.courses{
    width: 100%;
    /* margin-right: 30px; */
    padding: 20px;
    /* display: flex; */
    background: url(https://adminsite.inphb.app/Imagessiteprincipal/placeholder/backfilieresph.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.coursesleft{
    align-items: center;
    width: 90%;
    margin-left: auto;margin-right: auto;
}
.courseslefttitle{
    align-items: center;align-content: center;justify-content: center;display: flex;
    color: #f7f7f7;
    font-weight: bolder;
}
.courseslefttitle span{
    font-size: 45px;
    margin-right: 20px;
}
.courseslefttitle button{
    background-color: #012447d4;
    color: #f7f7f7;
    border: none;
    padding: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bolder;
    border: #f7f7f7 solid 1px;
    font-size: 15px;
    margin-top: 20px;
}
.courseslefttitle button:hover{
    background-color: #f7f7f7;
    color: #000000;
    border: #000000 solid 1px;

}
.lesfilieres{
    display: flex;
    width: 100%;
    align-items: center;align-content: center;justify-content: center;
    flex-wrap: wrap;
}
.lafiliere{
    width: 18%;
    min-height: 300px;
    background: url(https://adminsite.inphb.app/Imagessiteprincipal/placeholder/backlafiliere.jpg);
    background-size: contain;
    margin: 10px;
    border: #f7f7f7 solid 1px;
    border-radius: 10px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.6s;

}
.lafiliere1{
    width: 18%;
    min-height: 300px;
    background: url(https://adminsite.inphb.app/Imagessiteprincipal/placeholder/backlafiliere.jpg);
    background-size: contain;
    margin: 10px;
    border: #f7f7f7 solid 1px;
    border-radius: 10px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.6s;

}
.lafiliere:hover{
    background: url(https://adminsite.inphb.app/Imagessiteprincipal/placeholder/backlafiliererev.jpg);
    background-size: contain;
    transition: 0.6s;
    
}

.lafilieretitle{
    padding: 5px;
    text-align: center;
    background-color: #f7f7f7ad;
    font-weight: bolder;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #050505;

}
.lafilierecycle{
    padding: 7px;
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 12px; 
    color: #ff8c00;
    text-align: right;
}
.lafiliereniveau{
    padding: 7px;
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 11px; 
    color: #ffffff;
    text-align: center;
}
.lafiliereecole{
    padding: 7px;
    color: #f7f7f7;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 13px;
    text-align: center;
}
.lafilierespecialite{
    padding: 7px;
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 11px; 
    color: #05ccf9;
    text-align: center;
}
.clearIcon {
    position: relative;
    right: 40px;
    top: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 20px;
    font-weight: bolder;
    color: #116001;
  }

/* POUR LE MODAL */
.modalinside{
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: #bdbcbc8c;
    padding: 20px;
    border-radius: 14px;
    text-transform: uppercase;

}

.modalContent {
    background: #fff;
    border-radius: 15px;
    /* padding: 20px; */
    position: relative;
    /* max-width: 500px; */
    width: 50%;
    /* height: 500px; */
  }
  
  .modalOverlay {
    background: rgba(0, 141, 54, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

@media screen and (min-width: 320px) and (max-width: 767px) {
    .searchbar{
        width: 90%;
        align-items: center;align-content: center;justify-content: center;display: flex;
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 5px;
        background-color: #f7f7f700;
        padding: 5px;
        border-radius: 20px;
        margin-left: auto;margin-right: auto;
    }
    .labarre{
        width: 100%;
        align-items: center;align-content: center;justify-content: center;display: flex;
    }
    .labarre input{
        padding: 10px;
        width: 90%;
        border-radius: 15px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .clearIcon {
        position: relative;
        right: 40px;
        top: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 20px;
        font-weight: bolder;
        color: #116001;
      }
    .checkboxContainer{
        width: 100%;
        display: block;
        align-items: center; 
        align-content: center;
        justify-content: center;
        /* flex-direction: column; */
        text-align: left;
    }
    .checkboxContainer label{
        margin-right: 10px;
        font-weight: bolder;
        font-size: 12px;
    }
    .checkboxContainer label input{
        margin-right: 3px;
    }
    .destination{
        width: 100%;
        align-items: center;align-content: center;justify-content: center;display: flex;
        margin-top: 30px;
        font-weight: bolder;
        font-size: 15px;
    }
    .courses{
        width: 100%;
        margin-right: 10px;
        padding-bottom: 20px;
        display: block;
    }
    .courseslefttitle span{
        font-size: 20px;
        margin-top: 10px;
        /* margin-right: 20px; */
    }
    .lafiliere{
        width: 90%;
        min-height: 300px;
        background: url(https://adminsite.inphb.app/Imagessiteprincipal/placeholder/backlafiliere.jpg);
        background-size: contain;
        margin: 3px;
        border: #f7f7f7 solid 1px;
        border-radius: 5px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.6s;
        font-size: 6px;
    
    }
    .lafiliere1{
        width: 45%;
        min-height: 170px;
        background: url(https://adminsite.inphb.app/Imagessiteprincipal/placeholder/backlafiliere.jpg);
        background-size: contain;
        margin: 5px;
        border: #f7f7f7 solid 1px;
        border-radius: 5px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.6s;
        font-size: 7px;
    
    }
    .lafilieretitle{
        padding: 2px;
        text-align: center;
        background-color: #f7f7f7ad;
        font-weight: bolder;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: #050505;
        font-size: 10px;
    
    }
    .lafilierecycle{
        padding: 7px;
        font-weight: bolder;
        text-transform: uppercase;
        font-size: 8px; 
        color: #ff8c00;
        text-align: right;
    }
    .lafiliereecole{
        align-items: center;align-content: center;justify-content: center;
        padding: 2px;
        color: #f7f7f7;
        text-transform: uppercase;
        font-weight: bolder;
        font-size: 9px;
        text-align: center;
    }
    .courseslefttitle button{
        background-color: #012447d4;
        color: #f7f7f7;
        border: none;
        padding: 5px;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: bolder;
        border: #f7f7f7 solid 1px;
        font-size: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .courseslefttitle button:hover{
        background-color: #f7f7f7;
        color: #000000;
        border: #000000 solid 1px;
    
    }

    .modalContent {
        background: #fff;
        border-radius: 15px;
        /* padding: 20px; */
        position: relative;
        /* max-width: 500px; */
        width: 90%;
        /* height: 500px; */
      }
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .destination{
        width: 100%;
        align-items: center;align-content: center;justify-content: center;display: flex;
        margin-top: 40px;
        font-weight: bolder;
        font-size: 25px;
    }
    .courses{
        width: 100%;
        margin-right: 30px;
        padding: 20px;
        /* display: flex; */
    }

    .courseslefttitle span{
        font-size: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
        /* margin-right: 20px; */
    }
    .lafiliere{
        width: 30%;
        min-height: 300px;
        background: url(https://adminsite.inphb.app/Imagessiteprincipal/placeholder/backlafiliere.jpg);
        background-size: contain;
        margin: 8px;
        border: #f7f7f7 solid 1px;
        border-radius: 5px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.6s;
        font-size: 6px;
    
    }
    .lafiliere1{
        width: 30%;
        min-height: 200px;
        background: url(https://adminsite.inphb.app/Imagessiteprincipal/placeholder/backlafiliere.jpg);
        background-size: contain;
        margin: 10px;
        border: #f7f7f7 solid 1px;
        border-radius: 10px;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.6s;
    
    }
    .lafilieretitle{
        padding: 2px;
        text-align: center;
        background-color: #f7f7f7ad;
        font-weight: bolder;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: #050505;
        font-size: 13px;
    
    }
    .lafilierecycle{
        padding: 7px;
        font-weight: bolder;
        text-transform: uppercase;
        font-size: 10px; 
        color: #ff8c00;
        text-align: right;
    }
    .lafiliereecole{
        align-items: center;align-content: center;justify-content: center;
        padding: 2px;
        color: #f7f7f7;
        text-transform: uppercase;
        font-weight: bolder;
        font-size: 14px;
        text-align: center;
    }
    .courseslefttitle button{
        background-color: #012447d4;
        color: #f7f7f7;
        border: none;
        padding: 5px;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: bolder;
        border: #f7f7f7 solid 1px;
        font-size: 13px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .courseslefttitle button:hover{
        background-color: #f7f7f7;
        color: #000000;
        border: #000000 solid 1px;
    
    }

    .modalContent {
        background: #fff;
        border-radius: 15px;
        /* padding: 20px; */
        position: relative;
        /* max-width: 500px; */
        width: 80%;
        /* height: 500px; */
      }
}

