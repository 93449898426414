.trobcont{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    /* min-height: 10vh;
    background-color: #750202; */
    margin-top: 50px;
    display: grid;
    /* position: relative; */
}
.trobconttelphone{
    display: none;
}
@media screen and (max-width: 768px) {
    .trobconttelphone{
        display: block;
        width: 100%;
        align-content: center;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }
    .bouttontrombitel{
        width: 45%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 10px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 800;
        border-radius: 10px;
        font-size: 15px;
        text-align: center;
        cursor: pointer;

        /* background-color: ; */
    }
    .trobcont{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    /* min-height: 10vh;
    background-color: #750202; */
    margin-top: 20px;
    /* display: grid; */
    /* position: relative; */
    display: none;
    }
    .trombi{
        display: block;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        z-index: 99999;
        list-style: none;
        /* cursor: pointer;  */
    }
    .trombidivent{ 
        margin-left: auto;
        margin-right: auto;
        align-content: center;
        align-items: center;
        /* justify-content: center; */
        /* padding: 10px; */
        /* background-color: #02750a; */
        border: solid 1px #02750a;
        border-radius: 15px;
        display: flex;
        cursor: pointer;
        width: auto;
        min-height: 60px;
        /* height: 80px; */
        /* margin-left: 5px; */
    }
    .trombidivent h5{
        font-size: 5px;
        font-weight: 400;
    }
    .larger{
        width: 7%;
    }
    .normal{
        width: 5%;
    }
    .smallerxl{
        width: 2%;
    }
    .smaller{
        width: 5%;
    }
    .textcap{
        color: #ffffff;
        /* font-weight: 600; */
        font-size: 4px;
        text-transform: uppercase;
    }
    .textcap1{
        color: #ffffff;
        font-weight: 600;
        font-size: 4px;
        text-transform: uppercase;
    }
    .directiong{
        /* display: flex; */
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .directiong h5{
        /* display: flex; */
        /* margin-left: auto;
        margin-right: auto;
        width: 100%; */
        font-size: 5px;
    }
}
.trombi{
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    z-index: 99999;
    list-style: none;
    /* cursor: pointer;  */
}
.trombidivent{ 
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    /* justify-content: center; */
    /* padding: 10px; */
    /* background-color: #02750a; */
    border: solid 1px #02750a;
    border-radius: 15px;
    display: flex;
    cursor: pointer;
    width: auto;
    min-height: 60px;
    /* height: 80px; */
    /* margin-left: 5px; */
}
.trombidivent2{ 
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    /* justify-content: center; */
    /* padding: 10px; */
    /* background-color: #02750a; */
    /* border: solid 1px #02750a; */
    /* border-radius: 50%; */
    display: flex;
    cursor: pointer;
    width: 300px;
    height: 50px;
    /* min-height: 60px; */
    /* height: 80px; */
    /* margin-left: 5px; */
}
@media screen and (max-width: 768px) {
    .trombidivent2b{ 
        margin-left: auto;
        margin-right: auto;
        align-content: left;
        align-items: center;
        justify-content: center;
        /* padding: 10px; */
        /* background-color: #02750a; */
        /* border: solid 1px #02750a; */
        /* border-radius: 50%; */
        display: flex;
        cursor: pointer;
        text-align: left;
        width: auto;
        height: 50px;
        position: relative;
        /* display: none; */
        /* top: 50%;
        position: relative; */
        /* min-height: 60px; */
        /* height: 80px; */
        /* margin-left: 5px; */
    }
    .lignehoriz1{
        /* align-self: start; */
        align-content: center;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 20px;
        height: 100px;
        /* background-color: #02750a; */
        text-align: left;
        position: fixed;
        left: 20%;
        border-right: #019d0b solid 2px;
    
    }
    .sousdg1 {
        width: 50%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        align-content: center;
        border-top: #02750a solid 1px;
        /* border-left: #02750a solid 1px; */
    
        justify-content: space-between;
        box-sizing: border-box; /* Ajoutez cette ligne */
    }
    .trombidivfree{
        border: solid 1px #02750a;
        /* border-radius: 15px; */
        padding: 2px;
        min-height: 80px;
        text-transform: uppercase;display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        cursor: pointer;
    }
    .teltext{
        font-size: 2px;
        font-weight: 600;
    }
    .sousdg1a {
        width: 30%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        align-content: center;
        justify-content: center;
        /* align-items: center;
        align-content: center; */
        /* border-top: #02750a solid 1px; */
        /* border-left: #02750a solid 1px; */
    
        justify-content: center;
        
    }
    .lignehoriz{
        width: 10px;
        height: 1px;
        background-color: #02750a;
        text-align: left;
        position: fixed;
        left: 51%;
        display: block;
    
    }
    .nonvues{
        display: none;
    }
}
.trombidivent2b{ 
    margin-left: auto;
    margin-right: auto;
    align-content: left;
    align-items: center;
    justify-content: center;
    /* padding: 10px; */
    /* background-color: #02750a; */
    /* border: solid 1px #02750a; */
    /* border-radius: 50%; */
    display: flex;
    cursor: pointer;
    text-align: left;
    width: 300px;
    height: 50px;
    position: relative;
    /* top: 50%;
    position: relative; */
    /* min-height: 60px; */
    /* height: 80px; */
    /* margin-left: 5px; */
}
.trombidivent2a{ 
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    /* justify-content: center; */
    /* padding: 10px; */
    /* background-color: #02750a; */
    /* border: solid 1px #02750a; */
    /* border-radius: 50%; */
    display: flex;
    cursor: pointer;
    width: auto;
    height: auto;
    /* min-height: 60px; */
    /* height: 80px; */
    /* margin-left: 5px; */
}
.petitrond{
    border: #019d0b solid 1px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
.divWithHorizontalLine {
    text-align: left; /* Pour centrer le contenu horizontalement */
  }
  
  .divWithHorizontalLine::after {
    content: '';
    display: block;
    width: 50%; /* Ajustez la largeur selon vos besoins */
    margin: 0 auto; /* Pour centrer horizontalement */
    border-bottom: 1px solid rgb(138, 4, 4); /* Remplacez 'black' par la couleur de votre choix */
  }
  .lesflottants{
    /* height: auto; */
    position: relative;
    display: flex;
    top: -49%;
    left: 131%;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .lesflottants2{
    /* height: auto; */
    position: relative;
    display: flex;
    top: 50%;
    left: 50%;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .laligneflottante{
    height: 1px;
    border: #019d0b solid 1px;
    width: 40px;
  }.lecontenuediv{
    height: 70px;
    width: auto;
    padding: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .bgcell{
    background-color: #de9e07;
    border: #525252 solid 5px;
  }
  .bgcell2{
    background-color: #de9e07;
    /* border: #525252 solid 5px; */
  }
.trombidiventcont{
    width: 50%;
    /* background-color: #f77f00; */
    padding: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    /* border-left:  */
    border-right: #ffffff solid 2px;
}
.trombidiventcont img{
    width: 20%;
    border-radius: 50%;
    border: #ffffff solid 2px;
}
.trombidivent h5{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 17px;
    /* width: 40%; */
    /* padding-left: 20px; */
}
.trombidiv{ 
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    /* background-color: #02750a; */
    border: solid 1px #02750a;
    border-radius: 15px;
    display: flex;
    cursor: pointer;
    width: 70%;
    /* margin-left: 15px; */
    height: 100px;
}
.trombidivligne{
    height: 100px;
    width: 0.5px;
    /* border: solid 0.5px #02750a; */
    background-color: #02750a;
    margin-left: auto;
    margin-right: auto;
}
.trombidivsouligne{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
}
.longueligne{
    /* position: relative; */
    margin-left: auto;
    margin-right: auto;
    height: 42vh;
    width: 1px;
    /* left: 37%; */
    background-color: #019d0b;
}
.longueligne2{
    /* position: relative; */
    margin-left: auto;
    margin-right: auto;
    height: 45vh;
    width: 1px;
    /* left: 37%; */
    background-color: #019d0b;
}


/*LES FLOATS DES DIRECTIONS CENTRALES*/


.lesdirfloats{
    display: block;
    position: relative;
    margin-bottom: 20px;
    top:-42% ; /* position verticale centrée par rapport à la hauteur de la vue */
    left: 29.5%; /* position horizontale centrée par rapport à la largeur de la vue */
}
.lesdirections{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.ladirection1{
    height: 80px;
    width: 150px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 5px;
    border-radius: 15px;
    cursor: pointer;
    background-color: #7896DB;
}
.ladirection2{
    height: 80px;
    width: 150px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 5px;
    border-radius: 15px;
    cursor: pointer;
    background-color: #0936a0;
    border: #7896DB solid 5px;
}
.ladirection3{
    height: 80px;
    width: 150px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 5px;
    border-radius: 15px;
    cursor: pointer;
    background-color: #4b5d85;
    border: #7896DB solid 5px;
}
.ladirection4{
    height: 80px;
    width: 150px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 5px;
    border-radius: 15px;
    cursor: pointer;
    background-color: #0936a0;
    /* border: #7896DB solid 5px; */
}
.bgbleuunique2{
    background-color: #295fdd;
}
.textcapdir{
    color: #fff;
    font-size: 9.4px;
    text-transform: uppercase;
    font-weight: 600;
}
.lessignifications{
    width: 100%;
    position: relative;
    /* left: 50%; */
    top: -230%;
    background-color: rgba(237, 237, 237, 1);
    border-radius: 15px;
    /* padding: 50px; */
}
.lessigles{
    font-weight: 600;
    /* margin-left: auto;
    margin-right: auto; */
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-style: italic;
    line-height: 2;

}
.trombidivsd{ 
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    /* background-color: #02750a; */
    border: solid 1px #02750a;
    border-radius: 15px;
    display: flex;
    cursor: pointer;
    width: 80%;
    /* margin-left: 15px; */
    height: 100px;
}
.trombidivfree{
    border: solid 1px #02750a;
    /* border-radius: 15px; */
    padding: 10px;
    min-height: 80px;
    text-transform: uppercase;display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
}
.trombidivfreebn{
    /* border: solid 1px #02750a; */
    /* border-radius: 15px; */
    padding: 10px;
    min-height: 80px;
    text-transform: uppercase;display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
}
.trombidivfree2{
    /* border: solid 1px #02750a; */
    /* border-radius: 15px; */
    /* padding: 10px; */
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.larger{
    width: 40%;
}
.normal{
    width: 35%;
}
.smallerxl{
    width: 20%;
}
.smaller{
    width: 10%;
}
.textcap{
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}
.textcap1{
    color: #ffffff;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
}
.bggreen{
    background-color: #019d0b;
}
.brgrisunique{
    background-color: #C6C6C6;
}
.bgbleuunique{
    background-color: #7896DB;
}
.bgbleuuniquesd{
    background-color: rgb(193, 140, 75);
}
.bggrisclair{
    background-color: rgb(246, 246, 246);
}
.bggrisclaira{
    background-color: rgb(152, 150, 150);
}
.bgorange{
    background-color: #f77f00;
}
.bgviolet{
    background-color: #7b11c6;
}
.bgvioleta{
    background-color: rgb(179, 128, 216);
}
.bgbleu{
    /* background: linear-gradient( #ffffff, rgba(28, 93, 240, 1)); */
    background-color: rgba(28, 93, 240, 1);

}
.bgbleua{
    background-color: rgba(95, 133, 222, 0.7);
}
.bgbleub{
    background-color: rgba(120, 150, 219, 0.7);
}
.bgjaune{
    background-color: rgb(176, 159, 3);
}
.bgmarron{
    background-color: rgba(129, 86, 3, 1);
}
.bgbordeau{
    background-color: rgba(160, 4, 4, 1);
}
.bggris{
    background-color: rgba(121, 121, 121, 1);
}
.bgmauve{
    background-color: rgb(167, 2, 167);
}
.directiong{
    /* display: flex; */
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
.directorial{
    display: flex;
    border-top: 1px solid #02750a;
    justify-content: space-between;
    width: 100%;

}
.directorialcont{
    display: flex;
    justify-content: space-between;
}
.ligne1{
    /* position: relative; */
    width: 1px;
    background-color: #02750a;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
}
.ligne1xxl{
    /* position: relative; */
    width: 1px;
    background-color: #02750a;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
}
.ligne1xxla{
    /* position: relative; */
    width: 1px;
    background-color: #02750a;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
}
.ligne2{
    /* position: relative; */
    width: 1px;
    background-color: #02750a;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
}
.ligne3{
    /* position: relative; */
    width: 1px;
    background-color: #02750a;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
}
.sousdg1 {
    width: 70%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    border-top: #02750a solid 1px;
    /* border-left: #02750a solid 1px; */

    justify-content: space-between;
    box-sizing: border-box; /* Ajoutez cette ligne */
}
.sousdg1a {
    width: 70%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    /* align-items: center;
    align-content: center; */
    /* border-top: #02750a solid 1px; */
    /* border-left: #02750a solid 1px; */

    justify-content: center;
    
}
.lignehoriz1{
    /* align-self: start; */
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 200px;
    height: 150px;
    /* background-color: #02750a; */
    text-align: left;
    position: fixed;
    left: 51%;
    border-right: #019d0b solid 2px;

}
.lignehoriz{
    width: 200px;
    height: 1px;
    background-color: #02750a;
    text-align: left;
    position: fixed;
    left: 51%;
    display: block;

}
.brhalf{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
/* //LES POPUP GENERALISES  */

.opengestion{
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* padding-bottom: 30px; */
    background-color: rgba(91, 91, 91, 0.8); /* Couleur de fond semi-transparente */
    z-index: 9999999; /* Assure que la div est au premier plan */
    overflow-y: auto;
}
.letitre{
    background-color: #7896DB;
    width: 40%;
    margin-left: auto;margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    padding: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.letitre2{
    /* background-color: #7896DB; */
    width: 40%;
    margin-left: auto;margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    padding: 15px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.opengestion::-webkit-scrollbar {
    display: none;
}
.opengestion h5{
    color: #ffffff;
    font-weight: 800;
    text-transform: uppercase;
}
.opengestion button{
    background-color: rgba(121, 121, 121, 1);
    color: #ffffff;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-weight: 800;
}
.popupcont{
    width: 90%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: #7896DB solid 2px;
    /* padding-bottom: 30px; */
}
.borad15{
    border-radius: 15px;
}
.popupimage{
    width: 40%;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: #ffffff dashed 2px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.popupimage img{
    border-radius: 50%;
    width: 25%;
    border: #ffffff solid 2px;
    margin-bottom: 20px;
}
.popupposte{
    width: 40%;
}

/* LES POPUP AVEC LES SOUS DIRECTIONS */
.popupavecsd{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 30px;
}
.imagedirectur{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 30px;
}
.imagedirecturcb{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-top: 30px;
}
.imagedirecturcb img{
    width: 20%;
    border-radius: 50%;
    border: rgb(185, 145, 3) solid 3px ;
}
.imagedirectur img{
    width: 20%;
    border-radius: 50%;
    border: #7896DB solid 2px ;
}
.dirtitle{
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.popupimagedir{
    width: 30%;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: #ffffff dashed 2px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.popupimagedirdg{
    width: 40%;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: #ffffff solid 2px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 80vh;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.popupimagedirdg h5{
    text-align: left;
}
.popupimagedir img{
    border-radius: 50%;
    width: 30%;
    border: #7896DB solid 2px;
    margin-bottom: 20px;
}
.popuppostedirbureau{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.popuppostedir{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.popupsd {
    display: flex;
    padding: 20px;
    border-radius: 15px;
    align-items: center;
    align-content: center;
    justify-content: center;
    animation: wipe 1s;
    /* width: 90%; */
    /* border-bottom: black solid 2px; */
    /* margin-bottom: 10px; */
}
.popupsdspe {
    display: flex;
    padding: 20px;
    border-radius: 25px;
    align-items: center;
    align-content: center;
    justify-content: center;
    animation: wipe 1s;
    /* width: 90%; */
    border-bottom: black solid 3px;
    /* margin-bottom: 10px; */
}
.popupsddg {
    display: flex;
    padding: 20px;
    border-radius: 15px;
    align-items: center;
    align-content: center;
    justify-content: center;
    animation: wipe 1s;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    /* border-bottom: black solid 2px; */
    /* margin-bottom: 10px; */
}
.popupsdcb {
    display: flex;
    padding: 20px;
    border-radius: 15px;
    align-items: center;
    align-content: center;
    justify-content: center;
    animation: wipe 1s;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    /* border-bottom: black solid 2px; */
    /* margin-bottom: 10px; */
}
.popupsdbur {
    display: flex;
    padding: 20px;
    border-radius: 25px;
    align-items: center;
    align-content: center;
    justify-content: center;
    animation: wipe 1s;
    /* border-bottom: black solid 2px; */
    margin-bottom: 10px;
}

@keyframes wipe {
    from {
        clip-path: polygon(0 0, 0 0, 100% 0, 0 0);
    }
    to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}
.soudirecteursbureau{
    width: 40%;
    /* text-align-last: left; */
    /* display: flex; */
    align-items: center;
    align-content: center;
    justify-content: center;
}
.soudirecteursbureau h5{
    font-weight: 15px;
}
.soudirecteurs{
    width: 30%;
    /* text-align-last: left; */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: #fff solid 2px;
    height: 25vh;
    /* padding-top: 30px; */
    /* text-align: center; */
}
.soudirecteursdg{
    width: 30%;
    /* text-align-last: left; */
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: #fff solid 2px;
    height: 25vh;
    /* padding-top: 30px; */
    /* text-align: center; */
}
.soudirecteursdg h5{
    color: #02183e;
    font-weight: 800;
    font-size: 20px;
    text-align-last: left;
    line-height: 1.5;
}
.soudirecteurs img{
    width: 50%;
    border-radius: 50%;
    border: #ffffff solid 2px ;
    margin-bottom: 10px;
}
.soudirecteurs h5{
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}
.chefsservices{
    width: 60%;
    text-align-last: left;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.services{
    display: flex;
    /* border-bottom: #fff solid 1px; */
    padding: 10px;
    align-items: center;
    align-content: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: #ffffff solid 1px;
}
.servicesyeb{
    display: flex;
    /* border-bottom: #fff solid 1px; */
    padding: 10px;
    align-items: center;
    align-content: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: rgb(1, 14, 39) solid 3px;
    border-radius: 25px;
}
.servicesheb{
    display: flex;
    /* border-bottom: #fff solid 1px; */
    padding: 10px;
    /* align-items: center; */
    /* align-content: center;
    justify-content: center;
    align-content: center;
    align-items: center; */
    /* justify-content: center; */
    text-align: center;
    border-bottom: #ffffff solid 1px;
}
.serviceslast{
    display: flex;
    /* border-bottom: #fff solid 1px; */
    padding: 10px;
    align-items: center;
    align-content: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* border-bottom: #ffffff solid 1px; */
}

.nomservice{
    width: 70%;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.nomserviceheb{
    width: 100%;
    display: flex;
    /* text-align: center; */
    /* align-items: center; */
    /* align-content: center; */
    /* justify-content: center; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}
.nomserviceheb img{
    width: 40%;
}
.nomservicehebtitre{
    position: relative;
    left: -20%;
    width: 60%;
    /* text-align: left; */
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
}
.nomservicehebimg{
    width: 40%;
}
.nomservicedg{
    width: 60%;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.nomservicedg h5{
    font-size: 18px;
    text-align: left;
}
.nomservicedg h6{
    font-size: 15px;
    text-transform: uppercase;
    text-align: left;
}
.lestiresdessousservicesdg{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #011643;
    font-weight: 800;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;

}
.nomservice h5{
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
}

.imagechef{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    /* padding-left: 40px; */
}
.imagechefheb{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    /* align-content: center;
    align-items: center;
    justify-content: center; */
    text-align: center;
    display: flex;
    /* padding-left: 40px; */
}
.imagechefheb img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: #ffffff solid 1px ;
    margin-bottom: 10px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.imagechefdg{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    /* padding-left: 40px; */
}
.imagechefdg img{
    width: 35%;
    border-radius: 50%;
    border: #ffffff solid 1px ;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.imagechef1{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* display: flex; */
    /* padding-left: 40px; */
}
.imagechef1 img{
    width: 30%;
    border-radius: 50%;
    border: #ffffff solid 1px ;
    margin-bottom: 10px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.imagechef h6{
    /* width: 40%; */
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
}
.imagechef1 h6{
    /* width: 40%; */
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
}
.imagechef img{
    width: 45%;
    border-radius: 50%;
    border: #ffffff solid 1px ;
    margin-bottom: 10px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
