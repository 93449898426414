@import 'utils';

* {
    font-family: 'Roboto', 'Segoe Ui', 'Montserrat', sans-serif;
}
body {
    width: 100%;
    margin: 0px;
    background-color: #fff;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
.body-text {
    font-family: 'Montserrat', 'Roboto', sans-serif;
    font-size: 1rem;
    border-left: 5px solid #000;
    padding-left: 10px;
    font-size: 16px;
    line-height: 26px;
    @media (max-width: 780px) {
        font-size: 0.85rem;
        line-height: 30px;
    }
    @media (max-width: 430px) {
        font-size: 0.83rem;
        line-height: 30px;
    }
    @media (max-width: 410px) {
        font-size: 0.72rem;
        line-height: 30px;
    }
}
.menu-items {
    @media (max-width: 400px) {
        flex: 0 0 auto;
        margin-right: 10px;
    }
} 

.active {
    display: block;
}
.body-highlight {
    font-family: 'Segoe Ui', 'Roboto', sans-serif;
    font-weight: small;
    font-size: 2rem;
    margin-top: 2em;
    text-align: center;
    @media (max-width: 780px) {
        font-size: 1.5rem;
    }
    @media (max-width: 430px) {
        font-size: 1.3rem;
    }
    @media (max-width: 410px) {
        font-size: 1.1rem;
    }
}

.header-text {
    font-family: 'Roboto', 'Segoe Ui', sans-serif;
    font-size: 2.5rem;
    line-height: 26px;
    text-transform: uppercase;
    @media (max-width: 780px) {
        font-size: 2rem;
        line-height: 30px;
    }
}

.title-text {
    font-family: 'Roboto', 'Segoe Ui', sans-serif;
    font-size: 1.5rem;
    line-height: 26px;
    text-transform: uppercase;
    color: $bleu-fonce;
    margin: 1.5rem;
    text-align: center;
    @media (max-width: 780px) {
        font-size: 1rem;
        line-height: 30px;
    }
}

.text-shadow {
    text-shadow: -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000,
        1px 1px 10px #141414;
    color: #fff;
    background-color: transparent;
}

.ecole-title-text {
    font-family: 'Trebuchet MS', 'Bebas Neue', sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: $bleu-fonce;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 5px;
    text-align: center;
    line-height: 26px;
    @media (max-width: 780px) {
        font-size: 1.3rem;
        line-height: 30px;
    }
}

.reey {
    font-family: 'Reey Regular';
    font-size: 3rem;
}
.underline {
    background-image: linear-gradient(120deg, $esmg 0%, #d39469 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 105%;
    transition: background-size 0.25s ease-in;
    &:hover {
        background-size: 100% 88%;
    }
}

.page-giant-text {
    font-family: 'Trebuchet MS', 'Bebas Neue', sans-serif;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: $bleu-fonce;
    margin: 16px;
    text-align: center;
    line-height: 26px;
    @media (max-width: 780px) {
        font-size: 2.3rem;
        line-height: 30px;
    }
}

.button-text {
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 24px;
    @media (max-width: 780px) {
        font-size: 18px;
    }
}

.logos {
    height: 26px;
    width: 26px;
}

.logo {
    height: 90px;
    width: 90px;
}

.logopays {
    height: 38px;
    width: 38px;
}

.mylogo {
    height: 35px;
    width: 35px;
    margin-right: -9px;
    margin-top: -10px;
    @media (max-width: 780px) {
        height: 30px;
        width: 30px;
    }
    @media (max-width: 620px) {
        height: 25px;
        width: 25px;
        margin: auto;
    }
}

.filiere-wrapper {
    display: flex !important;
    border: 1px solid #ddd;
    overflow-x: auto !important;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
}
a {
    text-decoration: none;
}
.wrapper-item {
    min-width: 110px !important;
    line-height: 110px !important;
    background-color: #ddd;
    margin-right: 2px;
}
.mybtn {
    @include button-style;
    color: #fff;
    background: $orange-bouton;
    @media (max-width: 780px) {
        font-size: 13px;
    }
    @media (max-width: 430px) {
        font-size: 11px;
    }
    @media (max-width: 365px) {
        font-size: 9px;
    }
    &:hover {
        background: #fff;
        color: $orange-bouton;
        transform: scale(1.05);
        background-position: -3.75em;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        transition: all 0.3s ease-in-out;
    }
    &:active {
        transform: scale(1);
        background-position: 31.25em;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
}

.vert {
    &::before {
        content: '.';
        font-size: 40px;
        color: #0dba1e;
    }
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slide-active {
    filter: blur(0px);
    opacity: 1;
    transform: scale(1.1);
}

div#localize-powered-by{
    &>span{
        display: none !important;
    }
}

#localize-active-lang{
    &>span{
        display: none !important;
    }
}
// #localize-widget-langs{
//     position: fixed !important;
//     top: 100px !important;
//     left: 20px !important;
//     cursor: pointer !important;
//     width: 100% !important;
// }
// a#localize-active-lang{
//     text-decoration: none !important;
//     position: fixed !important;
//     top: 80px !important;
//     left: 20px !important;
//     cursor: pointer !important;
//     border-radius: 50% !important;
//     max-width: 225px !important;
//     max-height: 205px !important;
//     /* border: 1px solid #7ac034; */
//     padding: 15px !important;
//     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
//     background-color: #fff;
// }

// #localize-widget{
//     max-width: 20px !important;
// }

.fade-in {
    -webkit-animation-name: fadeInOpacity;
    animation-name: fadeInOpacity;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}


.right-to-left {
    animation: sortLeft 2s ease-in-out forwards;
}

.left-to-right {
    animation: sortRight 2s ease-in-out forwards;
}

.bottom-to-top {
    animation: sortBottom 2s ease-in-out forwards;
}

.top-to-bottom {
    animation: sortTop 2s ease-in-out forwards;
}

@keyframes fadeInOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes sortLeft {
    from {
        transform: translateX(-999px);
    }
    to {
        transform: translateX(0px);
    }
}

@keyframes sortRight {
    from {
        transform: translateX(999px);
    }
    to {
        transform: translateX(0px);
    }
}

@keyframes sortBottom {
    from {
        transform: translateY(999px);
    }
    to {
        transform: translateY(0px);
    }
}

@keyframes sortTop {
    from {
        transform: translateY(-999px);
    }
    to {
        transform: translateY(0px);
    }
}
