.emacontainer{
    width: 100%;
    /* height: 200px; */
    align-items: center;
    align-content: center;
    justify-content: center;
}
.emahead{
    height: 200px;
    background-color: rgba(17, 58, 140, 1);
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    display: flex;
}
.ematexthead{
    color: #fff;
    font-size: 55px;
    font-weight: 800;
}
.emaka{
    background-color: rgba(17, 58, 140, 1);
    color: #fff;
    height: 60px;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 120px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    font-weight: 800;
    /* padding: 10px; */
    padding-bottom: 20px;
}
.emaka img{
    width: 50%;
}
.ematitre{
    text-align: center;
    margin-top: 30px;
    font-size: 25px;
    text-transform: uppercase;
    color: #363535;
}
.emaprojets{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
}
.emaprojets1{
    margin-left: auto;margin-right: auto;
    text-align: center;
    width: 100%;
}
.emaprojettitle{
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
    font-size: 20px;
    background-color: rgba(17, 58, 140, 1);
    padding: 10px;
    color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.emaprojettitlea{
    margin-top: 20px;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
    font-size: 20px;
    background-color: rgb(255, 153, 0);
    padding: 10px;
    color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.emaprojettitle2{
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
    font-size: 14px;
    background-color: rgba(17, 58, 140, 0.7);
    padding: 10px;
    color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-top: 5px;
}
.emaprojettitle2a{
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 800;
    font-size: 14px;
    background-color: rgba(255, 153, 0, 0.7);
    padding: 10px;
    color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-top: 5px;
}
.ematableau{
    margin-top: 20px;
    width: 100%;
    text-align: center;
}
.ematableau table{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* display: flex; */
    text-transform: uppercase;
}

.colcol{
    background-color: #000 !important;
    color:#fff;
    padding: 5px;
    border-bottom: solid 1px #fff;
    border-right: solid 1px #fff;
}
.colcol2{
    background-color: rgba(17, 58, 140, 1);
    color:#fff;
    padding: 5px;
    border-bottom: solid 1px #fff;
}
.colcol3{
    background-color: rgba(255, 153, 0, 1);
    color:#fff;
    padding: 5px;
    border-bottom: solid 1px #fff;
}
.colcol2:hover{
    cursor: pointer;
    background-color: rgba(17, 58, 140, 0.2);
    color: #000;
}
.colcol3:hover{
    cursor: pointer;
    background-color: rgba(255, 153, 0, 0.2);
    color: #000;
}
.tableauema{
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
}
@media screen and (max-width: 990px){
    .ematexthead{
        color: #fff;
        font-size: 30px;
        font-weight: 800;
    }
    .ematitre h2{
        font-size: 18px;
    }
    .ematitre span{
        font-size: 15px;
    }
    .emaka img{
        width: 40%;
    }
    .emaprojets{
        margin-top: 15px;
    }
    .emaprojettitle{
        font-size: 13px;
        width: 90%;
    }
    .emaprojettitlea{
        font-size: 13px;
        width: 90%;
    }
    .emaprojettitle2{
        font-size: 9px;
        width: 90%;
    }
    .emaprojettitle2a{
        font-size: 9px;
        width: 90%;
    }
    .ematableau table{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        /* display: flex; */
        text-transform: none;
        font-size: 9px;
    }

}