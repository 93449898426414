@import url('https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap');

.buttonvideo{
    height: 100px;
    background-color: rgba(000, 000, 000, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.buttonvideo1{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 150px;
    background-color: #077f00;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
}
.buttonvideo1 h5{
    color: #fff;
    text-transform: uppercase;
    margin-top: auto;
    margin-bottom: auto;
}
.buttonvideo1 a{
    color: #fff;
}
.buttonvideo1:hover{
    background-color: black;
}

.verif{
    width: 0%;
    display: none;

}
.bouttonverif{
    display: none;
    width: none;

}
.bouttonverif:hover{
    background-color: #656565;
    border: #fff solid 2px;
}


.slideToRight {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    animation: slideToRight 5s ease-in-out;
    transition: margin-left 4.3s;
    z-index: 999;
    position: fixed;
    top: -0.5%;
    left: 43%;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    padding: 10px;
    height: 50px;
    background-color:#077f00;
    border: #fff solid 1px;
     border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}
.verif3{
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}
.slideToRight:hover{
    animation: slideToRight 5s ease-in-out;
    height: 70px;
    transition: 5s;
    transform-origin: 15%;
}

.sectionbiblio{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
}

.bibliotheque{
    /* color: #fff; */
    text-align: center;
    cursor: pointer;
}

.bibliotheque img {
    width: 25%;
    border-radius: 50%;
    border: #fff solid 2px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: -5px -1px 5px rgba(0, 0, 0, 0.6);
}
.bibliotheque img:hover {
    width: 25%;
    border-radius: 50%;
    border: #ffffff solid 2px;
    background-color: rgba(2, 1, 59, 0.8);
    box-shadow: none;
}
.bibliotheque h5{
    text-transform: uppercase;
    font-family: 'Sigmar One';
    color: #fff;
    text-shadow: 1px 1px 2px black;
    font-size: 18px;
}
.bibliotheque h5:hover{
    color: #0a012a;
}
.lacan{
    display: flex;
    background: linear-gradient(to right, rgb(255, 128, 0), white);
    /* height: 100px; */
    width: auto;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}
.imagecan{
    width: 20%;
    /* padding: 5px; */
    text-align-last: left;
    /* background-color: #0a012a; */
    border-top-right-radius: 55px;
    display: block;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);

}
.imagecan img{
    width: 40%;
    padding: 10px;
}
.decomptecan{
    /* display: none; */
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    color: #077f00;
    font-weight: 800;
    font-size: 25px;
}
@media screen and (max-width: 990px) {
    .lacan{
        display: flex;
        background: linear-gradient(to right, rgb(255, 128, 0), white);
        /* height: 100px; */
        width: auto;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
        border-bottom-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        margin-left: 10px;
    }
    .imagecan{
        width: 63%;
        /* padding: 5px; */
        text-align-last: left;
        /* background-color: #0a012a; */
        border-top-right-radius: 35px;
        /* margin-left: 10; */
        display: block;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.7);
    
    }
    .imagecan img{
        width: 40%;
        padding: 10px;
    }
    .decomptecan{
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        align-content: center;
        justify-content: center;
        display: flex;
        color: #077f00;
        font-weight: 800;
        font-size: 10px;
    }
}
@keyframes slideToRight {
    0% {
        margin-left: -3600px;
    }
    100% {
        margin-left: 0px;
        
    }
}

.buttonvideo2.slideToRight {
    animation: slideToRight 3s ease-in-out;
    transition: margin-left 1s;
}

@media screen and (max-width:768px) {
    .bouttonverif{
        display: block;
        width: auto;
        text-transform: uppercase;
        background-color: #0a012a;
        font-weight: 600;
        border: #fff solid 1px;
        color: #fff;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 5px;
        font-size: 10px;
        cursor: pointer;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    .verif{
        width: auto;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        left: 0px;
        user-select: none;
    }
    .slideToRight {
        display: none;
        animation: slideToRight 5s ease-in-out;
        transition: margin-left 4.3s;
        z-index: 999;
        position: fixed;
        top: -0.5%;
        left: 45%;
        border-bottom-left-radius: 10px;
        height: 50px;
    }
    .bibliotheque img {
        width: 40%;
        border-radius: 50%;
        border: #fff solid 1px;
        background-color: rgba(255, 255, 255, 0.5);
        box-shadow: -5px -1px 5px rgba(0, 0, 0, 0.6);
        margin-bottom: 8px;
    }

    .bibliotheque h5{
        text-transform: uppercase;
        font-family: 'Sigmar One';
        color: #fff;
        text-shadow: 1px 1px 2px black;
        font-size: 8px;
    }
}