$bleu-fonce : #02083a;
$orange-inp: #f47920;
$vert-inp: #008D36;
$bleu-inp: #079edb;
$orange-bouton: #ee8303;
$cpge : #096678;
$esa : #00a651;
$escae : #234d9d;
$esi : #74294b;
$esmg : #cc580a;
$espe : #1d4851;
$estp : #612c85;
$edp : #f7a600;
$efcpc: #bf0538;
$animate: all 0.2s ease-in-out;

@mixin button-style {
    display: inline-block;
    padding: 0.5em 1.5em;
    margin-bottom: 0.438em;
    text-decoration: none;
    border-radius: 3.125em;
    cursor: pointer;
    outline: none;
    text-transform: uppercase;
    font-weight: small;
};

