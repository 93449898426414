.slider_container{
    width: 100%;
}

.slider_slides{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;align-content: center;justify-content: center;
}

@keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .slider_text1.active {
    animation: slideUp 0.1s forwards; /* Animation moves text up and fades in */
}
.slider_text1{
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0; /* Start invisible */
    transform: translateY(100%); /* Start moved down */
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    padding: 40px;
    border-radius: 15px;
    top: 75%;
    /* right: 0; */
    left: 65%;
    /* left: 20%; */
    color: #fff;
    /* display: block; */
    display: flex;
    z-index: 9999;
    /* margin-bottom: 30px; */
    background-color: rgba(237, 236, 236, 0.897);
    /* border: #fff solid 2px; */
    align-items: center;align-content: center;justify-content: center;text-align: center;
}
.button1{
    /* position: absolute; */
    min-width: 150px;
    height: 50px;
    text-transform: uppercase;
    font-weight: 800;
    border-radius: 15px;
    font-size: 15px;
    border: #fff solid 2px;
    background-color: rgb(0, 141, 54);
    color: #fff;
    margin-right: 30px;

}
.button1:hover{
    background-color: #fff;
    color: rgb(0, 141, 54);
    border: rgb(0, 141, 54) solid 1px;
}
.button2{
    /* position: absolute; */
    min-width: 150px;
    height: 50px;
    text-transform: uppercase;
    font-weight: 800;
    border-radius: 10px;
    font-size: 15px;
    border: #fff solid 2px;
    background-color: rgb(255, 136, 0);
    color: #fff;
    /* margin-right: 30px; */
}
.button2:hover{
    background-color: #fff;
    color: rgb(255, 136, 0);
    border: rgb(255, 136, 0) solid 1px;
}
.button2b{
    /* position: absolute; */
    width: 250px;
    height: 60px;
    text-transform: uppercase;
    font-weight: 800;
    border-radius: 15px;
    font-size: 15px;
    border: #fff solid 2px;
    background-color: rgb(115, 115, 115);
    color: #fff;
    /* margin-right: 30px; */
}
.slider_text2{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 70%;
    /* right: -50%; */
    left: 20%;
    color: #fff;
}
.slick-prev, .slick-next {
    border-radius: 50%;
    display: flex;
    align-items: center; justify-content: center;align-content: center; text-align: center;
    z-index:9 ; /* Assurez-vous qu'ils sont au-dessus des autres éléments */
    opacity: 1; /* Rend les flèches visibles */
    background: rgba(0, 0, 0, 0.4); /* Ajoute un fond pour les rendre plus visibles */
    color: white; /* Change la couleur de l'icône des flèches */
    width: 60px; /* Définit la largeur des flèches */
    height: 60px; /* Définit la hauteur des flèches */
    top: 50%; /* Les positionne au milieu verticalement */
    transform: translateY(-50%); /* Centre les flèches verticalement */
}

.slick-prev {
    left: 10px;
}

.slick-next {
    right: 10px;
}

.slick-prev:before, .slick-next:before {
    font-size: 45px; /* Ajuste la taille des icônes des flèches */
    color: white; /* Couleur des icônes des flèches */
}

@media screen and (min-width: 320px) and (max-width:767px){
    .slider_text1{
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        opacity: 0; /* Start invisible */
        transform: translateY(100%); /* Start moved down */
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        padding: 0px;
        border-radius: 8px;
        top: 60%;
        /* right: 0; */
        left: 45%;
        /* left: 20%; */
        color: #fff;
        /* display: block; */
        display: flex;
        z-index: 9999;
        /* border: #fff solid 1px; */
        /* margin-bottom: 30px; */
        /* background-color: rgba(255, 255, 255, 0.6); */
        align-items: center;align-content: center;justify-content: center;text-align: center;
    }
    .button1{
        /* position: absolute; */
        min-width: 60px;
        height: 25px;
        text-transform: uppercase;
        font-weight: 800;
        border-radius: 8px;
        font-size: 8px;
        border: #fff solid 1px;
        background-color: rgb(0, 141, 54);
        color: #fff;
        margin-right: 10px;
    
    }
    .button2{
        /* position: absolute; */
        min-width: 60px;
        height: 25px;
        text-transform: uppercase;
        font-weight: 800;
        border-radius:8px;
        font-size: 8px;
        border: #fff solid 1px;
        background-color: rgb(255, 136, 0);
        color: #fff;
        /* margin-right: 30px; */
    }

    .slick-prev, .slick-next {
        border-radius: 50%;
        display: flex;
        align-items: center; justify-content: center;align-content: center;
        z-index:9 ; /* Assurez-vous qu'ils sont au-dessus des autres éléments */
        opacity: 1; /* Rend les flèches visibles */
        background: rgba(0, 0, 0, 0.3); /* Ajoute un fond pour les rendre plus visibles */
        color: white; /* Change la couleur de l'icône des flèches */
        width: 40px; /* Définit la largeur des flèches */
        height: 40px; /* Définit la hauteur des flèches */
        top: 50%; /* Les positionne au milieu verticalement */
        transform: translateY(-50%); /* Centre les flèches verticalement */
    }
    
    .slick-prev {
        left: 15px;
    }
    
    .slick-next {
        right: 15px;
    }
    
    .slick-prev:before, .slick-next:before {
        font-size: 30px; /* Ajuste la taille des icônes des flèches */
        color: white; /* Couleur des icônes des flèches */
    }
    
}

@media screen and (min-width: 768px) and (max-width:1024px) {
    .slider_text1{
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        opacity: 0; /* Start invisible */
        transform: translateY(100%); /* Start moved down */
        width: 40%;
        height: 80px;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        padding: 40px;
        border-radius: 15px;
        top: 60%;
        /* right: 0; */
        left: 50%;
        /* left: 20%; */
        color: #fff;
        /* display: block; */
        display: flex;
        z-index: 9999;
        /* margin-bottom: 30px; */
        /* background-color: rgba(255, 255, 255, 0.6); */
        align-items: center;align-content: center;justify-content: center;text-align: center;
    }

    .slick-prev, .slick-next {
        border-radius: 50%;
        display: flex;
        align-items: center; justify-content: center;align-content: center;
        z-index:9 ; /* Assurez-vous qu'ils sont au-dessus des autres éléments */
        opacity: 1; /* Rend les flèches visibles */
        background: rgba(0, 0, 0, 0.3); /* Ajoute un fond pour les rendre plus visibles */
        color: white; /* Change la couleur de l'icône des flèches */
        width: 60px; /* Définit la largeur des flèches */
        height: 60px; /* Définit la hauteur des flèches */
        top: 50%; /* Les positionne au milieu verticalement */
        transform: translateY(-50%); /* Centre les flèches verticalement */
    }
    
    .slick-prev {
        left: 10px;
    }
    
    .slick-next {
        right: 10px;
    }
    
    .slick-prev:before, .slick-next:before {
        font-size: 40px; /* Ajuste la taille des icônes des flèches */
        color: white; /* Couleur des icônes des flèches */
    }
    
}
